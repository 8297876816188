import React, { useState , useRef} from 'react';
import Select, { components } from 'react-select';

const StyleSelector = ({ styleLan, handleChangeStyLan, isTranslating, selectedStyle }) => {
  // 将 styleLan 转换为 react-select 需要的格式
  const options = styleLan.map((Lan) => ({
    value: Lan.eng, // 选项的值
    label: Lan.china, // 选项的显示文本
  }));

  // 当前选中的值
  const selectedOption = options.find((option) => option.value === selectedStyle);

  // 处理选项变化
  const handleChange = (selectedOption) => {
    handleChangeStyLan({ target: { value: selectedOption.value } });
	setIsSearchables(false)
  };
  
  const [isSearchables, setIsSearchables] = useState(false);
  	const selectRef = useRef(null); 
  	const [menuIsOpen, setMenuIsOpen] = useState(false);
  
  
  
  	const CustomDropdownIndicator = (props) => {
  	  const handleClick = (e) => {
  		e.preventDefault();
  		e.stopPropagation();
  
  		// if (props.selectProps.menuIsOpen) {
  		//   props.selectProps.onMenuClose();
  		// } else {
  		//   props.selectProps.onMenuOpen();
  		// }
  		setIsSearchables(false)
  		
  		if (selectRef.current) {
  			selectRef.current.focus(); 
  			setMenuIsOpen(!menuIsOpen);
  		}
  	};
  
    const handleSearchClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
  	  setIsSearchables(true)
  	  if (selectRef.current) {
  		selectRef.current.focus(); 
  		setMenuIsOpen(!menuIsOpen);
  	  }
    };
  
    return (
      <components.DropdownIndicator {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            onMouseDown={handleClick}
            onTouchEnd={handleClick}
            style={{ cursor: 'pointer', padding: '0 4px' }}
          >
             <img src="/down.png"style={{ width: '20px', height: '20px' }} />
          </div>
          <div
            onMouseDown={handleSearchClick}
            onTouchEnd={handleSearchClick}
            style={{ cursor: 'pointer', padding: '0 4px' }}
          >
            <img src="/search.png" alt="搜索图标" style={{ width: '20px', height: '20px' }} />
          </div>
        </div>
      </components.DropdownIndicator>
    );
  };
  
  
  
  

  return (
    <Select
	 ref={selectRef}
      aria-label="音色"
      value={selectedOption} // 当前选中的值
      onChange={handleChange} // 选项变化时的回调
      options={options} // 所有选项
      isDisabled={isTranslating} // 是否禁用
      placeholder="" // 占位符
      isSearchable={isSearchables} // 禁用搜索功能
      onBlur={() => setIsSearchables(false)} // 失去焦点时关闭搜索功能
      menuIsOpen={menuIsOpen}
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={() => setMenuIsOpen(false)}
     styles={{
      control: (provided,state) => ({
        ...provided,
        fontSize: '14px',
        padding: '2px',
        textAlign: 'left',
		backgroundColor: '#3F3F41', // 修改背景色
		borderColor:  '#3F3F41' , // 聚焦时边框颜色
		borderRadius: "12px 12px 12px 12px",
		color: 'white',
		'&:hover': {
		  borderColor: '#2684FF', // 鼠标悬停时边框颜色
		},
      }),
      option: (provided) => ({
        ...provided,
        color: 'black', // 设置下拉选项的文本颜色为黑色
        fontSize: '14px',
        textAlign: 'left',
      }),
      input: (provided) => ({
        ...provided,
        color: 'white', // 设置输入框的字体颜色为白色
		borderRadius: "12px 12px 12px 12px"
      }),
      placeholder: (provided) => ({
        ...provided,
        color: 'white', // 设置占位符颜色
      }),
       singleValue: (provided) => ({
      				...provided,
      				color: 'white', // 设置选中值的字体颜色
      }),
      
     }}
	 components={{
        IndicatorSeparator: null, // 移除竖杠
		DropdownIndicator: CustomDropdownIndicator, // 使用自定义下拉箭头
	  }}
    />
  );
};

export default StyleSelector;