import React, {useEffect, useState, useRef} from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Stack from 'react-bootstrap/Stack'
import config from '../config.json';
import {
    AudioConfig,
    SpeechTranslationConfig,
    TranslationRecognizer,
    SpeechSynthesizer,
    ResultReason,
    CancellationReason
} from 'microsoft-cognitiveservices-speech-sdk'
import { Howl } from 'howler';
import LanguageSelect from './LanguageSelect'
import Select from './Select.js'
import SoundWaveContainer from './SoundWave.js'
import './common.css'

const API_KEY = process.env.REACT_APP_COG_SERVICE_KEY
const API_LOCATION = process.env.REACT_APP_COG_SERVICE_LOCATION

let recognizer

function TranslationComponent() {
	const [styleLan, setStyleLan] = useState([]);
	const [selectedStyle, setSelectedStyle] = useState(""); // 当前选择的风格
    const [selectedValue, setSelectedValue] = useState('W');
    const [translatedText, setTranslatedText] = useState("")
    const [translatingText, setTranslatingText] = useState("")
    const [isTranslating, setIsTranslating] = useState(false)
    const playQueue = useRef([])          // 新增播放队列
    const isPlayingRef = useRef(false)    // 新增播放状态跟踪
    const textRef = useRef()
	const [selectObj, setSelectObj] = useState('en:en-US') 
	const [sourceLang, setSourceLang] = useState('zh-Hans:zh-CN'); // 新增原始语种状态

	let data = {}
    let dataY = {}
	
	const uncheckedImage = '/unchecked.png'; // 未选中时的图片
	const checkedImage = '/checked.png'; // 选中时的图片

    // 开始/停止监听
    const toggleListener = () => {
        if (!isTranslating) {
            startRecognizer()
            setTranslatedText("")
        } else {
            stopRecognizer()
        }
    }

    useEffect(() => {
		
		//init("en:en-US", "zh-Hans:zh-cn",selectedStyle);
		init(selectObj, sourceLang , selectedStyle , selectedValue);
        return () => {
            console.log('组件卸载...')
            if (recognizer) {
                stopRecognizer()
            }
        }
    }, [selectedStyle,selectedValue,selectObj,sourceLang])

    // 创建翻译识别器（仅创建不启动）
    const createRecognizer = (audioStream) => {
        console.log('原语言', dataY,)
        console.log('语言', data)
        const lan = data.language
        const lanY = dataY.lang
        const audioConfig = AudioConfig.fromStreamInput(audioStream)
        // 创建翻译配置
        const translationConfig = SpeechTranslationConfig.fromSubscription(API_KEY, API_LOCATION)
        translationConfig.speechRecognitionLanguage = lanY  // 原语音语言
        translationConfig.addTargetLanguage(lan)             // 翻译目标语言（此处为英文，可根据需要增加其它语言）

        recognizer = new TranslationRecognizer(translationConfig, audioConfig)

        // 实时翻译中间结果
        recognizer.recognizing = (s, e) => {
            if (e.result.reason === ResultReason.TranslatingSpeech) {
                const interimTranslation = e.result.translations.get(lan)
                setTranslatingText(interimTranslation)
                textRef.current.scrollTop = textRef.current.scrollHeight
            }
        }

        // 完整翻译结果处理
        recognizer.recognized = (s, e) => {
            setTranslatingText("")
            //console.log(e.result)
            if (e.result.reason === ResultReason.TranslatedSpeech) {
                const finalTranslation = e.result.translations.get(lan)
                setTranslatedText(prev => prev + finalTranslation + " ")
                textRef.current.scrollTop = textRef.current.scrollHeight
                // 翻译完成后调用 TTS 播放翻译内容
                speakTranslatedText(finalTranslation)
            } else if (e.result.reason === ResultReason.NoMatch) {
                console.log("未匹配：无法识别语音。")
            }
        }

        // 处理取消和错误情况
        recognizer.canceled = (s, e) => {
            console.log(`取消：原因=${e.reason}`)
            if (e.reason === CancellationReason.Error) {
                console.log(`错误代码=${e.errorCode}`)
                console.log(`错误详情=${e.errorDetails}`)
                console.log("请检查语音资源密钥和区域设置是否正确。")
            }
            recognizer.stopContinuousRecognitionAsync()
        }

        recognizer.sessionStopped = (s, e) => {
            console.log("会话停止。")
            recognizer.stopContinuousRecognitionAsync()
        }
    }

    // 启动翻译识别器
    const startRecognizer = () => {
        recognizer.startContinuousRecognitionAsync()
        setIsTranslating(true)
    }

    // 停止翻译识别器
    const stopRecognizer = () => {
        setIsTranslating(false)
        recognizer.stopContinuousRecognitionAsync()
    }

    // 调用语音合成，播放翻译后的文本
    const speakTranslatedText = (text) => {
        // 将新文本加入队列
        playQueue.current.push(text)
        // 尝试处理队列
        processQueue()

    }

    // 新增队列处理方法
    const processQueue = () => {
        // 如果正在播放或队列为空则返回
        if (isPlayingRef.current || playQueue.current.length === 0) return

        // 标记开始播放
        isPlayingRef.current = true
        const textToPlay = playQueue.current.shift()

        const synthesizer = new SpeechSynthesizer(
            SpeechTranslationConfig.fromSubscription(API_KEY, API_LOCATION),
            null
        )
        
		 const voiceName = selectedStyle;
		 console.log('当前风格',voiceName)
        let ssml = `
    <speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xml:lang="${data.lang}">
      <voice name="${voiceName}">
        <prosody rate="0%" pitch="0%">
          ${textToPlay}
        </prosody>
      </voice>
    </speak>`
        synthesizer.speakSsmlAsync(
            ssml,
            result => {
                console.log(result)
                const blob = new Blob([result.audioData], {type: 'audio/wav'})
                const url = URL.createObjectURL(blob)
                // const audio = new Audio(url)

                // audio.play()

                // audio.addEventListener('ended', () => {
                //     URL.revokeObjectURL(url)
                //     synthesizer.close()
                //     // 播放结束处理后续队列
                //     isPlayingRef.current = false
                //     processQueue()
                // })
				const sound = new Howl({
				  format: ['wav', 'mp3'],
				  src: [url], // 路径根据你的音频文件调整
				  onend: () => {
				    console.log('end!!!')
				    URL.revokeObjectURL(url)
				    synthesizer.close()
				    // 播放结束处理后续队列
				    isPlayingRef.current = false
				    processQueue()
				  },
				});
				sound.play()
            },
            error => {
                console.error(error)
                synthesizer.close()
                // 出错时继续处理队列
                isPlayingRef.current = false
                processQueue()
            }
        )
    }

    // 导出文本到本地文件
    const export2txt = (text) => {
        const blob = new Blob([text], {type: "text/plain"})
        const url = URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.download = "translation.txt"
        link.href = url
        link.click()
    }

    const init = (value, valueY,lanStyle,xb) => {
		console.log('sourceLang',valueY)
		console.log('selectObj',value)
		console.log('性别',xb)
		console.log('selectedStyle',lanStyle)
        let arr = config.Lanuage.filter(d => d.value === value)
		data.language = arr[0].language
		data.label = arr[0].label
		data.voiceW = arr[0].voiceW
		data.voiceM = arr[0].voiceM
		data.lang = arr[0].lang
		data.value = arr[0].value
       let arrY = config.Lanuage.filter(d => d.value === valueY)
		dataY.language = arrY[0].language
		dataY.label = arrY[0].label
		dataY.voiceW = arrY[0].voiceW
		dataY.voiceM = arrY[0].voiceM
		dataY.lang = arrY[0].lang
		dataY.value = arrY[0].value
		 // 根据性别更新风格列表
		const styles = xb === 'W' ? data.voiceW : data.voiceM;
		console.log('性别列表',styles)
		setStyleLan(styles);
		if(lanStyle == undefined || lanStyle == ""){
			setSelectedStyle(styles[0]?.eng || ''); // 默认选择第一个风格
			console.log('初始化风格',styles[0]?.eng || '')
		}else{
			setSelectedStyle(lanStyle)
		}
		
        
        if (recognizer) {
            stopRecognizer()
        }
        const constraints = {
            video: false,
            audio: {
                channelCount: 1,
                sampleRate: 16000,
                sampleSize: 16,
                volume: 1
            }
        }
        const getMedia = async (constraints) => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia(constraints)
                createRecognizer(stream)
            } catch (err) {
                alert(err)
                console.log(err)
            }
        }
        getMedia(constraints)
    }
    const handleChange = (event) => {
		const value = event.target.value
		setSelectObj(value); // 更新 selectObj 的状态
  //       init(value, sourceLang);
		// setSelectedValue('W')
		setSelectedStyle("")  
		
    }
	const handleSourceLangChange = (event) => {
	        const value = event.target.value;
		    setSourceLang(value); // 更新原始语种的状态
			// init(selectObj, value);
			// setSelectedValue('W')
			setSelectedStyle("")  
	}
		
    const radiochange = (event) => {
		setSelectedValue(event.target.value);
		setSelectedStyle("")  
    }
	const handleChangeStyLan = (event) => {
      setSelectedStyle(event.target.value);
	  console.log("选择后的当前风格",event.target.value)
    }


    return (
        <header className="App-header">
		
            <Container  style={{marginTop:"1rem"}}>
			
			<div style={{ fontWeight: "bold",  color: "#FFFFFF" ,marginBottom:'1rem'}}>
			   账号信息
			 </div>
			
			
			 <div style={{ fontWeight: "500",  color: "#FFFFFF" }}>
			    登录账号：18838899323
			  </div>
			  
			  
			  <div style={{ fontWeight: "500", color: "#FFFFFF" ,marginBottom:'20px'}}>
			     剩余金额： <label style={{color:"#FF6E46"}}>6378.5</label> 元
			   </div>
			   
			 <div style={{
			      width: "100%", // 线的宽度
			      height: "1px", // 线的高度
			      backgroundColor: "#FFFFFF", // 线的颜色
			      opacity: 0.2, // 透明度
			    }} />
				
			  <div style={{ fontWeight: "bold",  color: "#FFFFFF" ,marginTop:'20px'}}>
			     语音配置
			   </div>
			   
                <Row>
                    <Form>
					{/* 原始语种 */}
					<Form.Group as={Row} className="my-3">
						<Stack direction="horizontal" gap={2} className="align-items-center">
							<Form.Label column sm={2} className="text-nowrap col-3 col-sm-3" style={{textAlign:'left'}}>
								原始语种
							</Form.Label>
							<div style={{ flex: 1 }}>
								<LanguageSelect
								  isTranslating={isTranslating}
								  sourceLang={sourceLang}
								  handleSourceLangChange={handleSourceLangChange}
								  isSearchable={false}
								/>
								</div>
						</Stack>
					</Form.Group>
                      {/* 目标语种 */}
                        <Form.Group as={Row} className="my-3">
                            <Stack direction="horizontal" gap={2} className="align-items-center">
                                <Form.Label column sm={2} className="text-nowrap col-3 col-sm-3" style={{textAlign:'left'}}>
                                    目标语种
                                </Form.Label>
								<div style={{ flex: 1 }}>
									<LanguageSelect
									  isTranslating={isTranslating}
									  sourceLang={selectObj}
									  handleSourceLangChange={handleChange}
									  isSearchable={false}
									/>
									</div>


                            </Stack>
                        </Form.Group>

                        {/* 性别 */}
                        <Form.Group as={Row} className="my-3">
                            <Stack direction="horizontal" gap={2} className="align-items-center">
                                <Form.Label as="legend" column sm={2} className="text-nowrap col-3 col-sm-3" style={{textAlign:'left'}}>
                                    性&emsp;&emsp;别
                                </Form.Label>
                                <Stack direction="horizontal" gap={3}>
                                    <Form.Check
                                        value="M"
                                        type="radio"
                                        label="男声"
                                        name="formHorizontalRadios"
                                        id="formHorizontalRadios1"
                                        checked={selectedValue === 'M'}
                                        onChange={radiochange}
                                        disabled={isTranslating}
										className="custom-radio"
										 label={
										  <div className="custom-radio-image">
											<img
											  src={selectedValue === 'M' ? checkedImage : uncheckedImage}
											  alt="男声"
											/>
											<span>男声</span>
										  </div>
										}
                                    />
                                    <Form.Check
                                        value="W"
                                        type="radio"
                                        label="女声"
                                        name="formHorizontalRadios"
                                        id="formHorizontalRadios2"
                                        onChange={radiochange}
                                        checked={selectedValue === 'W'}
                                        disabled={isTranslating}
										className="custom-radio"
										 label={
										  <div className="custom-radio-image">
											<img
											  src={selectedValue === 'W' ? checkedImage : uncheckedImage}
											  alt="女声"
											/>
											<span>女声</span>
										  </div>
										}
                                    />
                                </Stack>
                            </Stack>
                        </Form.Group>
						
						{/* 风格 */}
						 <Form.Group as={Row} className="my-3">
						     <Stack direction="horizontal" gap={2} className="align-items-center">
						         <Form.Label column sm={2} className="text-nowrap col-3 col-sm-3" style={{textAlign:'left'}}>
						             音&emsp;&emsp;色
						         </Form.Label>
								 <div style={{ flex: 1 }}>
								 	<Select
								 	  isTranslating={isTranslating}
								 	  styleLan={styleLan}
								 	  handleChangeStyLan={handleChangeStyLan}
									  selectedStyle = {selectedStyle}
								 	/>
								 	</div>
						     </Stack>
						 </Form.Group>

                        {/* 翻译结果 */}
                        <Form.Group className="my-3">
                            <Form.Control
                                as="textarea"
                                placeholder="这里显示翻译结果"
                                value={`${translatedText}${translatingText}`}
                                readOnly
                                style={{height: '160px', resize: 'none',borderRadius: "12px 12px 12px 12px",backgroundColor: '#3F3F41',borderColor:  '#3F3F41' ,marginTop:'26px',color: 'white',}}
                                ref={textRef}
                            />

                        </Form.Group>
						
                    </Form>
                </Row>
            </Container>
			
			
			{isTranslating ? <div style={{height:'30px'}}><SoundWaveContainer waveCount={7} barCount={20} /> </div>: <div style={{height:'30px'}}></div>}
			
			{/* 按钮组 */}
			
			<div
			  style={{
			    width: '173px',
			    height: '45px',
			    background: isTranslating ? "#FFFFFF" : "#FF6E46",
				color: isTranslating ? "#FF6E46" : "#FFFFFF",
			    borderRadius: "14px",
			   // fontSize: '14px',
			    display: 'flex', // 使用 Flexbox 布局
			    alignItems: 'center', // 垂直居中
			    justifyContent: 'center', // 水平居中
			    gap: '8px', // 图标和文字之间的间距
				marginTop:'10px',
				marginBottom:'10px'
			  }}
			  onClick={toggleListener}
			>
			  {isTranslating ? (
			    <>
			      <img src="/stop.png" width="21px" alt="停止图标" />
			      停止
			    </>
			  ) : (
			    <>
			      <img src="/start.png" width="21px" alt="开始图标" />
			      开始
			    </>
			  )}
			</div>
			

			{translatedText !== '' && !isTranslating && (
				<Button variant="secondary" onClick={() => export2txt(translatedText)}  style={{width:'173px',borderRadius: "14px",}}>
					导出文本
				</Button>
			)}
			
        </header>
    )
}

export default TranslationComponent