import React, { useState , useRef} from 'react';
import Select, { components } from 'react-select';
import config from '../config.json';




const LanguageSelect = ({ isTranslating, sourceLang, handleSourceLangChange }) => {
  const options = config.Lanuage.map((Lan) => ({
    value: Lan.value,
    label: Lan.label,
  }));

  const [selectedOption, setSelectedOption] = useState(
    options.find((option) => option.value === sourceLang)
  );
    
	const [isSearchables, setIsSearchables] = useState(false);
	const selectRef = useRef(null); 
	const [menuIsOpen, setMenuIsOpen] = useState(false);



	const CustomDropdownIndicator = (props) => {
	  const handleClick = (e) => {
		e.preventDefault();
		e.stopPropagation();

		// if (props.selectProps.menuIsOpen) {
		//   props.selectProps.onMenuClose();
		// } else {
		//   props.selectProps.onMenuOpen();
		// }
		setIsSearchables(false)
		
		if (selectRef.current) {
			selectRef.current.focus(); 
			setMenuIsOpen(!menuIsOpen);
		}
	};

  const handleSearchClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
	  setIsSearchables(true)
	  if (selectRef.current) {
		selectRef.current.focus(); 
		setMenuIsOpen(!menuIsOpen);
	  }
  };

  return (
    <components.DropdownIndicator {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          onMouseDown={handleClick}
          onTouchEnd={handleClick}
          style={{ cursor: 'pointer', padding: '0 4px' }}
        >
           <img src="/down.png"style={{ width: '20px', height: '20px' }} />
        </div>
        <div
          onMouseDown={handleSearchClick}
          onTouchEnd={handleSearchClick}
          style={{ cursor: 'pointer', padding: '0 4px' }}
        >
          <img src="/search.png" alt="搜索图标" style={{ width: '20px', height: '20px' }} />
        </div>
      </div>
    </components.DropdownIndicator>
  );
};

  const handleChange = (selected) => {
    setSelectedOption(selected);
    handleSourceLangChange({ target: { value: selected.value } });
	setIsSearchables(false)
  }; 

  return (
  <div> 
          <Select
		    ref={selectRef}
            value={selectedOption}
            onChange={handleChange}
            options={options}
            isDisabled={isTranslating}
            placeholder="选择语种"
            isSearchable={isSearchables} // 禁用搜索功能
		    onBlur={() => setIsSearchables(false)} // 失去焦点时关闭搜索功能
			menuIsOpen={menuIsOpen}
			onMenuOpen={() => setMenuIsOpen(true)}
			onMenuClose={() => setMenuIsOpen(false)}

            styles={{
              control: (provided,state) => ({
                ...provided,
                fontSize: '14px',
                padding: '2px',
                textAlign: 'left',
				backgroundColor: '#3F3F41', // 修改背景色
				borderColor:  '#3F3F41' , // 聚焦时边框颜色
				borderRadius: "12px 12px 12px 12px",
				color: 'white',
				'&:hover': {
				  borderColor: '#2684FF', // 鼠标悬停时边框颜色
				},
              }),
              option: (provided) => ({
                ...provided,
                color: 'black', // 设置下拉选项的文本颜色为黑色
                fontSize: '14px',
                textAlign: 'left',
              }),
			  input: (provided) => ({
			    ...provided,
			    color: 'white', // 设置输入框的字体颜色为白色
				borderRadius: "12px 12px 12px 12px",
			  }),
			  placeholder: (provided) => ({
			    ...provided,
			    color: 'white', // 设置占位符颜色
			  }),
			   singleValue: (provided) => ({
				...provided,
				color: 'white', // 设置选中值的字体颜色
			  }),
			  
            }}
			 components={{
				   IndicatorSeparator: null, // 移除竖杠
			        DropdownIndicator: CustomDropdownIndicator, // 使用自定义下拉箭头
			      }}
		  />
		</div>
	  );
	};
	
	export default LanguageSelect;
