import React from 'react';
import './common.css'


const SoundWave = () => {
  return (
    <div className="sound-wave">
      {Array.from({ length: 10 }).map((_, index) => (
        <div key={index} className="sound-bar"></div>
      ))}
    </div>
  );
};

const SoundWaveContainer = ({ waveCount, barCount }) => {
  return (
    <div className="sound-wave-container">
      {Array.from({ length: waveCount }).map((_, index) => (
        <SoundWave key={index} barCount={barCount} />
      ))}
    </div>
  );
};

export default SoundWaveContainer;