import './App.css';
import Transcription from './Components/Transcription';
import SpeechToText from './Components/SpeechToText';


function App() {

  return (
    <div className="App">
      <Transcription />
    </div>
  );
}

export default App;
